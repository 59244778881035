import './General.css';
import './css/bootstrap.min.css';
import './css/style.css';
// import aboutpic from "./img/about.jpg";
import aboutpic from "./img/mechanic-mobile.webp";
import fourk from "./img/mechanic-4k.webp";
import medium from "./img/mechanic-1528.webp";
import small from "./img/mechanic-1000.webp";


function About() {

    return (
        <>
        
        <div class="container-xxl py-5" id='about'>
        <div class="container">
            <div class="row g-5">
                <div class="col-lg-6 pt-4" style={{minHeight: "400px"}}>
                    <div class="position-relative h-100 wow fadeIn" data-wow-delay="0.1s">
                        <img 
                        class="position-absolute img-fluid w-100 h-100" 
                        src={aboutpic} 
                        srcSet={`${small} 900w, ${medium} 1500w, ${fourk} 3000w`}
                        style={{objectFit: "cover"}} 
                        alt=""/>
                        <div class="position-absolute top-0 end-0 mt-n4 me-n4 py-4 px-5" style={{background: "rgba(0, 0, 0, .08)"}}>
                            <h1 class="display-4 text-white mb-0">35 <span class="fs-4">Years</span></h1>
                            <h4 class="text-white">Experience</h4>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <h6 class="text-primary text-uppercase"> About Us </h6>
                    <h1 class="mb-4"><span class="text-primary">Maine Autospares</span> Is The Best Place For Your Car Care</h1>
                    <p class="mb-4">
                        We have been providing high quality car repairs and spare parts alongside stellar customer service for over 35 years. 
                    
                        Contact us or call in with any automotive issues to see how we can help.</p>
                    <div class="row g-4 mb-3 pb-3">
                        <div class="col-12 wow fadeIn" data-wow-delay="0.1s">
                            <div class="d-flex">
                                <div class="bg-light d-flex flex-shrink-0 align-items-center justify-content-center mt-1" style={{width: "45px"}, {height: "45px"}}>
                                    <span class="fw-bold text-secondary">01</span>
                                </div>
                                <div class="ps-3">
                                    <h6>Professional and Expert</h6>
                                    <span>We have expertise across a broad range of automotive issues</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 wow fadeIn" data-wow-delay="0.3s">
                            <div class="d-flex">
                                <div class="bg-light d-flex flex-shrink-0 align-items-center justify-content-center mt-1" style={{width: "45px"}, {height: "45px"}}>
                                    <span class="fw-bold text-secondary">02</span>
                                </div>
                                <div class="ps-3">
                                    <h6>Quality Servicing Center</h6>
                                    <span>Our mechanic has decades of experience </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 wow fadeIn" data-wow-delay="0.5s">
                            <div class="d-flex">
                                <div class="bg-light d-flex flex-shrink-0 align-items-center justify-content-center mt-1" style={{width: "45px"}, {height: "45px"}}>
                                    <span class="fw-bold text-secondary">03</span>
                                </div>
                                <div class="ps-3">
                                    <h6>Spare Parts</h6>
                                    <span>We have extensive stock and can order required parts that aren't instore</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 wow fadeIn" data-wow-delay="0.5s">
                            <div class="d-flex">
                                <div class="bg-light d-flex flex-shrink-0 align-items-center justify-content-center mt-1" style={{width: "45px"}, {height: "45px"}}>
                                    <span class="fw-bold text-secondary">04</span>
                                </div>
                                <div class="ps-3">
                                    <h6>Vauxhall Specialists</h6>
                                    <span>We have extensive experience providing repairs and parts for Vauxhall cars </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <a href="" class="btn btn-primary py-3 px-5">Read More<i class="fa fa-arrow-right ms-3"></i></a> */}
                </div>
            </div>
        </div>
    </div>
    

        </>
    );
}

export default About;