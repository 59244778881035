import './General.css';
import './css/bootstrap.min.css';
import './css/style.css';

function Footer() {

    return (
        <>
        
        
    <div class="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s" id='contact'>
        <div class="container py-5">
            <div class="row g-5">
                
                <div class="col-lg-3 col-md-6">
                    <h4 class="text-light mb-4">Services</h4>
                    <a class="btn btn-link" >Engine Servicing</a>
                    {/* <a class="btn btn-link" href="">Tire Replacement</a> */}
                    <a class="btn btn-link" >Oil Changing</a>
                    <a class="btn btn-link" >MOT Checkup</a>
                    <a class="btn btn-link" >Spare Parts</a>
                </div>
                <div class="col-lg-3 col-md-6">
                    <h4 class="text-light mb-4">Opening Hours</h4>
                    <h6 class="text-light">Monday - Friday:</h6>
                    <p class="mb-4">09:00 - 17:00</p>
                    <h6 class="text-light">Saturday - Sunday:</h6>
                    <p class="mb-0">Closed</p>
                </div>
                <div class="col-lg-3 col-md-6">
                    <h4 class="text-light mb-4">Address</h4>
                    <p class="mb-2"><i class="fa fa-map-marker-alt me-3"></i>1 Old Mill Park, Cullybackey, Ballymena</p>
                    <p class="mb-2"><i class="fa fa-phone-alt me-3"></i>028 2588 0517</p>
                    <p class="mb-2"><i class="fa fa-envelope me-3"></i>dessie.maineautospares@outlook.com</p>
                    {/* <div class="d-flex pt-2">
                        <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-youtube"></i></a>
                        <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-linkedin-in"></i></a>
                    </div> */}
                </div>
                <div class="col-lg-3 col-md-6">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2294.842433502023!2d-6.357450884183347!3d54.88813468033098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48604b88c7ffdd2b%3A0xc362fab43ebd6db2!2s1%20Old%20Mill%20Park%2C%20Cullybackey%2C%20Ballymena%20BT42%201FA!5e0!3m2!1sen!2suk!4v1651444477000!5m2!1sen!2suk" width="300" height="225" style={{border: "0"}} 
                    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    
                </div>
            </div>
        </div>
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2294.842433502023!2d-6.357450884183347!3d54.88813468033098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48604b88c7ffdd2b%3A0xc362fab43ebd6db2!2s1%20Old%20Mill%20Park%2C%20Cullybackey%2C%20Ballymena%20BT42%201FA!5e0!3m2!1sen!2suk!4v1651444477000!5m2!1sen!2suk" width="600" height="450" style={{border: "0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
        <div class="container">
            <div class="copyright">
                <div class="row">
                    <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <a  href="#">1985 - 2022 : Maine Auto Spares</a>, All Rights Reserved

                        
                        
                    </div>
                    <div class="col-md-6 text-center text-md-end">
                        <div class="footer-menu">
                            <a href="#header">Home</a>
                            {/* <a href="">Cookies</a>
                            <a href="">Help</a>
                            <a href="">FAQs</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
    
    

        </>
    );
}

export default Footer;