import './General.css';
import './css/bootstrap.min.css';
import './css/style.css';

import pic1 from "./img/testimonial-1.jpg";
import pic2 from "./img/testimonial-2.jpg";
import pic3 from "./img/testimonial-3.jpg";
import pic4 from "./img/testimonial-4.jpg";


function Team() {

    const style1 = {
        width: "80px",
        height: "80px"
    }

    return (
        <>
        
        
        <div class="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s" id='testimonials'>
        <div class="container">
            <div class="text-center">
                <h6 class="text-primary text-uppercase">Testimonials</h6>
                <h1 class="mb-5">What Our Clients Say!</h1>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6 wow fadeInUp">

                    <h5 class="text-center">Curtis Tweed
                    <p>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    </p>
                    </h5>

                    <div class="testimonial-text bg-light text-center p-4">
                    <p class="mb-0">Really good place I have used them for over 10 years</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp">

                    <h5 class="text-center">Raymond Lynn
                    <p>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    </p>
                    </h5>

                    <div class="testimonial-text bg-light text-center p-4">
                    <p class="mb-0">Always very helpful. Mr Mooney is always willing to help with parts and accessories. There is also a skilled mechanic working there.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp">

                    <h5 class="text-center">Darryl Worthington
                    <p>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    </p>
                    </h5>

                    <div class="testimonial-text bg-light text-center p-4">
                    <p class="mb-0">Friendly helpful staff</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp">

                    <h5 class="text-center">Thomas Kirk
                    <p>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    </p>
                    </h5>
                    

                    <div class="testimonial-text bg-light text-center p-4">
                    <p class="mb-0">Good friendly people.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    

        </>
    );
}

export default Team;