import './General.css';
import './css/bootstrap.min.css';
import './css/style.css';

import { Button,  ButtonGroup, DropdownButton, Dropdown, MenuItem } from 'react-bootstrap';

// import DropDown from 'react-bootstrap/Dropdown';


function Header() {

    return (
        <>
        
        
    <div class="container-fluid bg-light p-0" id="header">
        <div class="row gx-0 d-none d-lg-flex">
            <div class="col-lg-7 px-5 text-start">
                <div class="h-100 d-inline-flex align-items-center py-3 me-4">
                    <small class="fa fa-map-marker-alt text-primary me-2"></small>
                    <small>1 Old Mill Park, Cullybackey, Ballymena </small>
                </div>
                <div class="h-100 d-inline-flex align-items-center py-3">
                    <small class="far fa-clock text-primary me-2"></small>
                    <small>Mon - Fri : 09:00 -  17:00</small>
                </div>
            </div>
            <div class="col-lg-5 px-5 text-end">
                <div class="h-100 d-inline-flex align-items-center py-3 me-4">
                    <small class="fa fa-phone-alt text-primary me-2"></small>
                    <small>028 2588 0517</small>
                </div>
                <div class="h-100 d-inline-flex align-items-center">
                    <a class="btn btn-sm-square bg-white text-primary me-1" href="https://www.facebook.com/MaineAutoSpares/"><i class="fab fa-facebook-f"></i></a>
                    <a class="btn btn-sm-square bg-white text-primary me-1" href="https://www.yelp.com/biz/maine-autospares-ballymena"><i class="fab fa-yelp"></i></a>
                    <a class="btn btn-sm-square bg-white text-primary me-1" href="https://www.autoinsider.co.uk/local/28615/maine-autospares"><i class="fa fa-car me-1"></i></a>

                </div>
            </div>
        </div>
    </div>
    


    
    <nav class="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0">
        <a href="index.html" class="navbar-brand d-flex align-items-center px-4 px-lg-5">
            <h2 class="m-0 text-primary"><i class="fa fa-car me-3"></i>Maine Autospares</h2>
        </a>
        <button type="button" class="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            
            <Dropdown>
  <Dropdown.Toggle variant="white" id="dropdown-basic">
  <span class="navbar-toggler-icon"></span>
  </Dropdown.Toggle>

  <Dropdown.Menu>
    <Dropdown.Item href="#about">About</Dropdown.Item>
    <Dropdown.Item href="#services">Services</Dropdown.Item>
    <Dropdown.Item href="#contact">Contact</Dropdown.Item>
    <Dropdown.Item href="#testimonials">Testimonials</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>
        </button>
        
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <div class="navbar-nav ms-auto p-4 p-lg-0">
                <a href="" class="nav-item nav-link active">Home</a>
                <a href="#about" class="nav-item nav-link">About</a>
                <a href="#services" class="nav-item nav-link">Services</a>
                <a href="#contact" class="nav-item nav-link">Contact</a>
                <a href="#testimonials" class="nav-item nav-link">Testimonials</a>
                {/* <div class="nav-item dropdown">
                    <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                    <div class="dropdown-menu fade-up m-0">
                        <a href="booking.html" class="dropdown-item">Booking</a>
                        <a href="team.html" class="dropdown-item">Technicians</a>
                        <a href="testimonial.html" class="dropdown-item">Testimonial</a>
                        <a href="404.html" class="dropdown-item">404 Page</a>
                    </div>
                </div> */}
                
            </div>
            {/* <a href="" class="btn btn-primary py-4 px-lg-5 d-none d-lg-block">Get A Quote<i class="fa fa-arrow-right ms-3"></i></a> */}
        </div>
    </nav>
    

        </>
    );
}

export default Header;