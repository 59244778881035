import './General.css';
import './css/bootstrap.min.css';
import './css/style.css';

import fourk from "./img/carengine2-4k.webp";
import medium from "./img/carengine2-1080.webp";
import small from "./img/carengine2-1000w.webp";
import mobile from "./img/carengine2-370w.webp";


function Carousel() {

    // const small = '300.jpg';
    // const medium = '768.jpg';
    // const large = 'carpic.jpg';
    // const fourk = 'carpic1-4k';

    return (
        <>
        
        
        <div class="container-fluid p-0 mb-5">
        <div id="header-carousel" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">

                    <img class="w-100" 
                    src={mobile} 
                    srcSet={`${small} 900w, ${medium} 1500w, ${fourk} 3000w`}

                    alt="Image"/>

                    <div class="carousel-caption d-flex align-items-center">
                        <div class="container">
                            <div class="row align-items-center justify-content-center justify-content-lg-start">
                                <div class="col-10 col-lg-7 text-center text-lg-start">
                                    <h6 class="text-white text-uppercase mb-3 animated slideInDown">Car Servicing </h6>
                                    <h1 class="display-3 text-white mb-4 pb-3 animated slideInDown">Qualified Car Repair Service Centre</h1>
                                    {/* <a href="" class="btn btn-primary py-3 px-5 animated slideInDown">Learn More<i class="fa fa-arrow-right ms-3"></i></a> */}
                                </div>
                                <div class="col-lg-5 d-none d-lg-flex animated zoomIn">
                                    {/* <img class="img-fluid" src={carpic2} alt=""/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="carousel-item">
                    <img class="w-100" src={carpic4} alt="Image"/>
                    <div class="carousel-caption d-flex align-items-center">
                        <div class="container">
                            <div class="row align-items-center justify-content-center justify-content-lg-start">
                                <div class="col-10 col-lg-7 text-center text-lg-start">
                                    <h6 class="text-white text-uppercase mb-3 animated slideInDown">// Car Servicing //</h6>
                                    <h1 class="display-3 text-white mb-4 pb-3 animated slideInDown">Qualified Car Wash Service Center</h1>
                                    <a href="" class="btn btn-primary py-3 px-5 animated slideInDown">Learn More<i class="fa fa-arrow-right ms-3"></i></a>
                                </div>
                                <div class="col-lg-5 d-none d-lg-flex animated zoomIn">
                                    <img class="img-fluid" src={carpic3} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            {/* <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#header-carousel"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button> */}
        </div>
    </div>
    

        </>
    );
}

export default Carousel;