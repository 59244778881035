import './General.css';
import './css/bootstrap.min.css';
import './css/style.css';

import pic1 from "./img/service-1.jpg";
import pic2 from "./img/service-2.jpg";
import pic3 from "./img/service-3.jpg";
import pic4 from "./img/service-4.jpg";

import React, { useState } from "react";

function Service2() {

    const data = [
    {
        id: "1",
        key: "1",
        title: "MOT Checkup",
        img: pic1,
        point1: "Comprehensive",
        point2: "Fast Quotation",
        point3: "Affordable",
        text: "Our MOT checkups will help identify any problems with your car ahead of an MOT test. We can provide a quotation for any " +
        "services required to bring your car up to specification."
    },
    {
        id: "2",
        key: "2",
        title: "Engine Servicing",
        img: pic2,
        point1: "Skilled and experienced mechanic",
        point2: "Quality servicing",
        point3: "Affordable",
        text: "Our experienced mechanic can provide an affordable quotation and a quick resolution to any engine related issues."
    },
    {
        id: "3",
        key: "3",
        title: "Oil Changing",
        text: "We can take care of the little things so you don't have to. From changing engine oil to replacing windscreen wipers, " +
        "we have the stock and experience to get what you need for a great price.",
        img: pic4,
        point1: "High quality products",
        point2: "Convenient",
        point3: "Affordable"
    }
    ];

    const [toggle, setToggle] = useState("1");

    return (
        <>
        
        
        <div class="container-xxl service py-5" id='services'>
        <div class="container">
            <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 class="text-primary text-uppercase"> Our Services </h6>
                <h1 class="mb-5">Explore Our Services</h1>
            </div>
            <div class="row g-4 wow fadeInUp" data-wow-delay="0.3s">
                <div class="col-lg-4">
                    <div class="nav w-100 nav-pills me-4">
                    {data.map(({ title, text, key, img }) => {
                        return (
                            <>
                            {toggle === key ? (
                            <>
                                <button class="nav-link w-100 d-flex align-items-center text-start p-4 mb-4 active" 
                            data-bs-toggle="pill" data-bs-target="#tab-pane-1" type="button" onClick={() => setToggle(key)}>
                            
                                
                                <i class="fa fa-car-side fa-2x me-3"></i>
                                <h4 class="m-0">{title}</h4>
                            </button>
                            </>
                            ) : <button class="nav-link w-100 d-flex align-items-center text-start p-4 mb-4" 
                            data-bs-toggle="pill" data-bs-target="#tab-pane-1" type="button" onClick={() => setToggle(key)}>
                            
                                
                                <i class="fa fa-car-side fa-2x me-3"></i>
                                <h4 class="m-0">{title}</h4>
                            </button>}

                            </>
                          );
                            

                        
                        })}

                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="tab-content w-100">
                    {data.map(({ title, text, key, img, point1, point2, point3 }) => {
                        return (
                            <>
                            {toggle === key ? (
                        <>
                            <div class="tab-pane fade show active" id="tab-pane-1">
                            <div class="row g-4">
                                
                                <div class="col-md-6">
                                    <h3 class="mb-3">{title}</h3>
                                    <p class="mb-4">{text}</p>
                                    <p><i class="fa fa-check text-success me-3"></i>{point1}</p>
                                    <p><i class="fa fa-check text-success me-3"></i>{point2}</p>
                                    <p><i class="fa fa-check text-success me-3"></i>{point3}</p>
                                    {/* <a href="" class="btn btn-primary py-3 px-5 mt-3">Read More<i class="fa fa-arrow-right ms-3"></i></a> */}
                                </div>
                                <div class="col-md-6" style={{minHeight: "350px"}}>
                                    <div class="position-relative h-100">
                                        <img class="position-absolute img-fluid w-100 h-100" src={img}
                                            style={{objectFit: "cover"}} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>
                        ) : null}
                            </>
                          );
                            

                        
                        })}
                        

                    </div>
                </div>
            </div>
        </div>
    </div>
    

        </>
    );
}

export default Service2;