import './General.css';
import './css/bootstrap.min.css';
import './css/style.css';

import pic1 from "./img/team-1.jpg";
import pic2 from "./img/team-2.jpg";


function Team() {

    return (
        <>
        
        
        <div class="container-xxl py-5">
        <div class="container">
            <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 class="text-primary text-uppercase">Our Technicians</h6>
                <h1 class="mb-5">The Maine Autospares Team</h1>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="team-item">
                        <div class="position-relative overflow-hidden">
                            <img class="img-fluid" src={pic1} alt=""/>
                            <div class="team-overlay position-absolute start-0 top-0 w-100 h-100">
                                Owner
                            </div>
                        </div>
                        <div class="bg-light text-center p-4">
                            <h5 class="fw-bold mb-0">Dessie Mooney</h5>
                            <small>Owner</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="team-item">
                        <div class="position-relative overflow-hidden">
                            <img class="img-fluid" src={pic2} alt=""/>
                            <div class="team-overlay position-absolute start-0 top-0 w-100 h-100">
                                World class mechanic 

                            </div>
                        </div>
                        <div class="bg-light text-center p-4">
                            <h5 class="fw-bold mb-0">Ronnie</h5>
                            <small>Mechanic</small>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    

        </>
    );
}

export default Team;