
import Header from "./Components/Header";
import Carousel from "./Components/Carousel";
import Service from "./Components/Service";
import About from "./Components/About";
import Service2 from "./Components/Service2";
import Team from "./Components/Team";
import TagManager from 'react-gtm-module'
import Testimonials from "./Components/Testimonials";
// import Contact from "./Components/Contact";

import Footer from "./Components/Footer";
import React, { useState, Component } from 'react';
const tagManagerArgs = {
        gtmId: 'G-JQE8V351YY'
}
TagManager.initialize(tagManagerArgs)

class App extends Component {

    
    render() {
        TagManager.dataLayer(tagManagerArgs)
      return (
  
        <>
        
        <Header />
        <Carousel />
        <About />
        {/* <Service /> */}
        <Service2 />
        {/* <Team /> */}
        <Testimonials />

        {/* <Contact /> */}


        <Footer />
        
        </>
        
      )
    }
    
    
  }

export default App;
